export type SidebarStatus = 'opening' | 'open' | 'closing' | 'closed';
type SidebarState = {
	status: SidebarStatus;
};
type SidebarReducerType = 'open' | 'close' | 'animation-end';
type SidebarReducerPayload = {
	type: SidebarReducerType;
};

export const INITIAL_SIDEBAR_STATE: SidebarState = { status: 'closed' };

export function sidebarReducer(state: SidebarState, payload: SidebarReducerPayload): SidebarState {
	let status: SidebarStatus = state.status;
	switch (payload.type) {
		case 'open':
			status = 'opening';
			break;
		case 'close':
			status = 'closing';
			break;
		case 'animation-end':
			if (state.status === 'closing') {
				status = 'closed';
			}

			if (state.status === 'opening') {
				status = 'open';
			}
			break;
	}
	return { status };
}
